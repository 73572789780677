import Topbar from "../../customComponents/withdrawMoneyy/TopBar"
import IndexDeleteAccount from "./IndexDeleteAccount"
import React from "react"

export default function index() {
  return (
    <div
      style={{
        background: "#E5E5E5",
      }}
    >
      <Topbar />
      <IndexDeleteAccount />
    </div>
  )
}
